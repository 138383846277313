import {Text} from "react-native";
import React from "react";

const INITIAL_STATE = {
    tr: {
        landing: {
            heading: 'NITT',
            secondaryHeading: 'Natural Intelligence\nTemperature Testing',
            sloganHeading: 'NATURAL INTELLIGENCE (NI)',
            sloganDescription: 'Doğal Zeka, Tüm canlıların vücut ISISINI düzenler.',
            sloganButton: 'DETAYLAR',
            sloganLink: 'https://chaava.com/tr',
            startTestButton: 'TESTE BAŞLA',
            addMember: 'AİLE ÜYESİ EKLE',
            me: 'Ben',
            userPastData: 'GEÇMİŞ VERİLERİNİZİN\nANALİZLERİ',
            memberPastData: 'AİLE ÜYELERİNİN\nANALİZLERİ',
            familyMembers: 'AİLE ÜYELERİ',
            earTesting: 'OPSİYONEL KULAK TESTİ',
        },
        points: {
            rightNeck: "Sağ Boyun",
            leftNeck: "Sol Boyun",
            rightNape: "Sağ Ense",
            leftNape: "Sol Ense",
            rightBelly: "Sağ Karın",
            leftBelly: "Sol Karın",
            rightBack: "Sağ Bel",
            leftBack: "Sol Bel",
            rightHandIn: "Sağ Avuç İçi",
            leftHandIn: "Sol Avuç İçi",
            rightHandOut: "Sağ El Üstü",
            leftHandOut: "Sol El Üstü",
            rightFootUp: "Sağ Ayak Üstü",
            leftFootUp: "Sol Ayak Üstü",
            rightFootDown: "Sağ Ayak Altı",
            leftFootDown: "Sol Ayak Altı",
            rightEar: "Sağ Kulak",
            leftEar: "Sol Kulak",
        },
        advising: {
            freeAdvising: 'Ücretsiz Danışmanlık',
            warning: 'Bu özellik sadece aktif üyeler içindir. Üyeliğinizi aktifleştirmek isterseniz, lütfen "ÜCRETSİZ DANIŞMANLIK" talep edin.',
        },
        addMember: {
            name: "Ad",
            nickName: "Takma Ad",
            heading: {
                addMember: 'AİLE ÜYESİ EKLE',
            },
        },
        beforeStart: {
            description: "Test öncesi temassız, kızılötesi termometre gerekecektir. (herhangi bir marka olabilir - lütfen termometrenin 34°C / 86°F sıcaklığa kadar ölçtüğünden emin olun.)",
            instructions: "BELİRTİLMİŞ ÇEŞİTLİ NOKTALARDAN ÖLÇÜM YAPIP VÜCUT ISILARINIZI SİSTEME GİRİN",
            goAhead: "BAŞLA",
        },
        general: {
            loginButton: "GİRİŞ YAP",
            signUpButton: "KAYIT OL",
            forgotPasswordButton: "PAROLAMI UNUTTUM",
            resetPasswordButton: "PAROLAMI SIFIRLA",
            logout: 'ÇIKIŞ YAP',
            add: 'EKLE',
            next: "DEVAM",
            menu: "MENÜ",
            warning: "Uyarı",
            passwordMatchError: 'Girdiğiniz parolalar eşleşmiyor.',
            errorOccurred: 'Hata Oluştu',
            fillAll: 'Lütfen tüm alanları doldurun.',
            sendRequest: "İSTEK GÖNDER",
            shareMessage: 'Apple Store veya Google Play Store\'dan NITT App\'i indirin!\nhttps://www.naturallaw.ch/nitt_app/',
            months: 'Ay',
            month: 'Ay',
            success: 'Başarılı',
            passwordResetEmailSent: 'Parola sıfırlama bağlantısı, email adresinize gönderildi.',
            resetYourPassword: 'Parolanızı Sıfırlayın',
        },
        measure: {
            instructions: "LÜTFEN GÖSTERİLDİĞİ ŞEKİLDE ÖLÇÜN",
        },
        menu: {
            membersLogin: 'Üye Girişi',
            freeSupport: 'Ücretsiz Danışmanlık Desteği',
            noHeatNoLive: 'NO HEAT - NO LIVE',
            legalPoints: 'Yasal Detaylar',
        },
        analysis: {
            heading: "NITT ANALİZ",
            leftSide: "VÜCUT SOL TARAF ÖLÇÜM GRAFİĞİ",
            rightSide: "VÜCUT SAĞ TARAF ÖLÇÜM GRAFİĞİ",
            overAll: "GÜNDÜZ VE GECE DETOKS PROGRAMLARIYLA VÜCUDUNUZU GÜNLÜK HİPERASİDASYONDAN ARINDIRMANIZI TAVSİYE EDERİZ.",
            saveResults: "SONUCU KAYDET",
            moreInfo: "DETOKS PROGRAMLARI",
            moreInfoLink: "https://chaava.com/tr/preventionset/",
            analysisFor: "%s İÇİN ANALİZLER",
        },
        suggestion: {
            heading: "NITT\nANALİZ HAKKINDA\nDAHA FAZLA BİLGİ",
            freeAdvising: 'ÜCRETSİZ\nDANIŞMANLIK',
            preventionKits: 'ÖNLEM\nKİTLERİ',
            // TURKCE
            content1: 'DOĞAL İMMÜN SİSTEMİNİZ İYİDİR.\nLütfen sağlığınıza dikkat etmeye devam edin',
            content2: 'Doğal bağışıklık sisteminiz -%s% oranında zayıflamışsa, günlük yaşamınız hafif rahatsızlıklardan etkilenebilir. Bu durumda kimyasız ve yan etkisiz Önlem BASIC Programı kiti ile, vücut ısınızı yeniden dengelemenizi tavsiye ederiz.',
            content3: 'Cilt tahrişleri ve hızlı cilt yaşlanması (kırışıklıklar, kuru cilt ...) ile karşı karşıya kalırsanız, size kimyasal ve yan etkiler içermeyen bir Prevention Advance Beauty Program kitini öneririz.',
            content4: 'Doğal bağışıklık sisteminiz -%s% oranında zayıflamışsa, günlük yaşamınız ilerlemiş rahatsızlıklardan etkilenebilir.\n\n' +
                'Natural Law\'a göre, fasya bağ dokusundaki biyolojik hasar nedeniyle vücut ısısı önemli ölçüde değişebilir. Elektronların vücuttan hızlı geçişi yavaşladıkça, sıcaklık 36° C\'nin altına düşer.\n\n' +
                'Bu durumda kimyasız ve yan etkisiz Önlem ADVANCED Programı kiti ile, vücut ısınızı yeniden dengelemenizi tavsiye ederiz.',
            content5: 'Doğal bağışıklık sisteminiz -%s% oranında zayıflamışsa, günlük yaşamınız kronik rahatsızlıklardan etkilenebilir.\n\n' +
                'Natural Law\'a göre, fasya bağ dokusundaki biyolojik hasar nedeniyle vücut ısısı önemli ölçüde değişebilir. Elektronların vücuttan hızlı geçişi yavaşladıkça, sıcaklık 35° C\'nin altına düşer.\n\n' +
                'Bu durumda kimyasız ve yan etkisiz Önlem CHRONIC Programı kiti ile, vücut ısınızı yeniden dengelemenizi tavsiye ederiz.',
        },
        placeholders: {
            email: "ornek@gmail.com",
            password: "parola",
            passwordAgain: "parola yeniden",
            phone: "telefon numarası",
            name: "ad",
            surname: "soyad",
            city: "şehir",
        },
        freeAdvising: {
            thankYou: 'Mesajınız için teşekkür ederiz.',
            thankYouDesc: 'Size en kısa zamanda ulaşacağız.',
        },
        errors: {
            emailNotValid: 'Girdiğiniz email adresi geçersiz.',
            phoneNotValid: 'Girdiğiniz telefon numarası geçersiz.',
            tryAgain: 'Lütfen daha sonra tekrar deneyin.',
            noData: 'Bu aile üyesi için daha önce hiç ölçüm yapılmamış.',
        },
        legalPoints: {
            heading: 'YASAL DETAYLAR',
            content: 'Dikkatinizi, sıcaklık okumalarının üreticinin test ekipmanlarına ve algoritmalarına bağlı olarak değişebileceği gerçeğine çekiyoruz. Uygun bir değerlendirme yapabilmek için hesaplamalarımızda belirli parametreleri dikkate aldık.\n\nNITT, İsviçre\'nin Zürih şehrinde Natural Law Scientific Systems tarafından geliştirilmiş bir teşhis aracıdır. Bu, tüm biyolojik sistemlerin, yani bitki örtüsü, hayvanlar ve insanların, kendi kendini düzenleyen ısıtma sistemleriyle doğal bağışıklığı düzenlediği gerçeğine dayanmaktadır.\n\nNITT servisi, tüm sorumluluklar dışında ve herhangi bir garantinin hariç tutulmasıyla gerçekleşir. NITT hizmeti tıbbi, psikiyatrik veya psikolojik tedavi teşkil etmez. Sunulan hizmetler kapsamında teşhis konulmamakta ve şifa vaadi verilmemektedir. NITT servisi gerekli bir tedaviyi doktorla değiştiremez.',
        },
        login: {
            dontHaveAccount: 'Hesabınız Yok Mu?',
            signUp: 'Üye Olun!',
        },
        header: {
            familyAccount: 'AİLE\nHESABI',
            menu: 'MENÜ',
            goBack: 'GERİ DÖN',
        },
        measurePoints: {
            left: '(Sol) ',
            right: '(Sağ) ',
            neck: 'Boyun',
            nape: 'Ense',
            back: 'Bel',
            handIn: 'Avuç İçi',
            handOut: 'Avuş Dışı',
            belly: 'Göbek',
            footUp: 'Ayak Üstü',
            footDown: 'Ayak Altı',
            rightSide: 'Vücudunuzun Sağ Tarafı',
            leftSide: 'Vücudununuzun Sol Tarafı',
            description: 'İşte uygulamadaki ölçüm verileriniz.',
            ear: 'Kulak',
        },
    },
    en: {
        landing: {
            heading: 'NITT',
            secondaryHeading: 'Natural Intelligence\nTemperature Testing',
            sloganHeading: 'NATURAL INTELLIGENCE (NI)',
            sloganDescription: 'Natural Intelligence regulates Body Temperatures of all Livings.',
            sloganButton: 'SEE MORE',
            sloganLink: 'https://chaava.com',
            startTestButton: 'START TEST',
            addMember: 'ADD MEMBER',
            me: 'Me',
            userPastData: 'ANALYSIS OF YOUR\nPAST DATA',
            memberPastData: 'FAMILY MEMBER\'S\nPAST DATA',
            familyMembers: 'FAMILY MEMBERS',
            earTesting: 'OPTIONAL EAR TESTING',
        },
        points: {
            rightNeck: "Right Neck",
            leftNeck: "Left Neck",
            rightNape: "Right Nape",
            leftNape: "Left Nape",
            rightBelly: "Right Belly",
            leftBelly: "Left Belly",
            rightBack: "Right Back",
            leftBack: "Left Back",
            rightHandIn: "Right Hand In",
            leftHandIn: "Left Hand In",
            rightHandOut: "Right Hand Out",
            leftHandOut: "Left Hand Out",
            rightFootUp: "Right Foot Up",
            leftFootUp: "Left Foot Up",
            rightFootDown: "Right Foot Down",
            leftFootDown: "Left Foot Down",
            rightEar: "Right Ear",
            leftEar: "Left Ear",
        },
        advising: {
            freeAdvising: 'Free Advising',
            warning: 'This function is available only for active members. If you want to get an active membership, please ask for "FREE ADVISING"',
        },
        addMember: {
            name: "Name",
            nickName: "Nickname",
            heading: {
                addMember: 'ADD FAMILY MEMBER',
            },
        },
        beforeStart: {
            description: "Prior testing you would need one of these, Non-contact, infrared Thermometers.\n(can be any trademark - please make sure that the thermometer can measure up to 34°C / 86°F)",
            instructions: "MEASURE FROM VARIOUS POINTS AND ENTER YOUR BODY TEMPERATURES",
            goAhead: "GO AHEAD",
        },
        general: {
            loginButton: "LOGIN",
            signUpButton: "SIGN UP",
            forgotPasswordButton: "FORGOT PASSWORD?",
            resetPasswordButton: "RESET PASSWORD",
            logout: 'LOG OUT',
            add: 'ADD',
            next: "NEXT",
            menu: "MORE",
            warning: "Warning",
            passwordMatchError: 'Password does not match.',
            errorOccurred: 'Error Occurred',
            fillAll: 'Please fill all fields.',
            sendRequest: "SEND REQUEST",
            shareMessage: 'Download NITT App from Apple Store or Google Play Store to test your Natural Immunity!\nhttps://www.naturallaw.ch/nitt_app/',
            months: 'Months',
            month: 'Month',
            success: 'Success',
            passwordResetEmailSent: 'Password reset email has been sent to your email address.',
            resetYourPassword: 'Reset Your Password',
        },
        measure: {
            instructions: "PLEASE MEASURE AS INDICATED",
        },
        menu: {
            membersLogin: 'Members Login',
            freeSupport: 'Free Advisor Support',
            noHeatNoLive: 'NO HEAT - NO LIVE',
            legalPoints: 'Legal Points',
        },
        analysis: {
            heading: "NITT ANALYSIS",
            leftSide: "BODY LEFT SIDE MEASUREMENTS OVERALL",
            rightSide: "BODY RIGHT SIDE MEASUREMENTS OVERALL",
            overAll: "PLEASE DETOXIFY YOUR BODY DAILY FROM HYPERACIDIFICATION WITH DETOX DAY & NIGHT PROGRAMS.",
            saveResults: "SAVE MY RESULTS",
            moreInfo: "MORE ON DETOX PROGRAMS",
            moreInfoLink: "https://chaava.com/preventionset/",
            analysisFor: "NITT ANALYSIS FOR: %s",
        },
        suggestion: {
            heading: "NITT\nMORE INFORMATION\nON ANALYSIS",
            freeAdvising: 'FREE\nADVISING',
            preventionKits: 'PREVENTION\nKITS',
            content1: 'YOU ARE FINE\nPlease continue to take care of your health.',
            content2: 'If your natural immune system is weakened by -%s% and your daily life can be affected by light disorders;\nWe recommend you for rebalancing your body temperature, an Prevention BASIC Program kit, without chemicals and side affects.',
            content3: 'If you are confronted with skin irritations and rapid skin aging (wrinkles, dry skin..), we recommend you an Prevention Advance Beauty Program kit, without chemicals and side affects.',
            content4: 'If your natural immune system is weakened by -%s% your daily life can be affected by advanced disorders!\n\nAccording to Natural Law, the the body temperature can vary significantly due to a biological facial connective-tissue damage. If the fast electron passage from the body slows down, its temperature drops below 36°C degrees.\n\nWe recommend you for rebalancing your body temperature, an Prevention ADVANCED Program kit, without chemicals and side affects.',
            content5: 'If your natural immune system is weakened by -%s% your daily life can be affected by chronic disorders!\n\nAccording to Natural Law, the the body temperature can vary significantly due to a biological facial connective-tissue damage. If the fast electron passage from the body slows down, its temperature drops below 35°C degrees.\n\nWe recommend you for rebalancing your body temperature, an Prevention CHRONIC Program kit, without chemicals and side affects.',
        },
        placeholders: {
            email: "example@gmail.com",
            password: "password",
            passwordAgain: "password again",
            phone: "phone number",
            name: "name",
            surname: "surname",
            city: "city",
        },
        freeAdvising: {
            thankYou: 'Thank you for your message.',
            thankYouDesc: 'We will get back to you soon.',
        },
        errors: {
            emailNotValid: 'Entered email address is not valid',
            phoneNotValid: 'Entered phone number is not valid',
            tryAgain: 'Please try again later.',
            noData: 'We have no analysis data related to selected family member.',
        },
        legalPoints: {
            heading: 'LEGAL POINTS',
            content: 'We draw your attention to the fact that the temperature readings can vary depending on the manufacturer testing equipments and algorithms. We have taken certain parameters into account in our calculations in order to make an appropriate assessment.\n\nNITT is a diagnosis Tool developed by Natural Law Scientific Systems in Zurich, Switzerland.  It’s based on the fact, that all biological systems, i.e. vegetation, animals and humans, are regulating natural immunity by their self-regulating heating system.\n\nNITT service take place under exclusion of all liability and under exclusion of any warranty. NITT service do not constitute medical, psychiatric or psychological treatment. Within the scope of the offered services, no diagnoses are made and no promises of healing are given. NITT service can not replace a necessary treatment with a doctor.',
        },
        login: {
            dontHaveAccount: 'Don\'t you have an account?',
            signUp: 'Sign Up!',
        },
        header: {
            familyAccount: 'FAMILY\nACCOUNT',
            menu: 'MORE',
            goBack: 'GO BACK',
        },
        measurePoints: {
            left: '(Left) ',
            right: '(Right) ',
            neck: 'Neck',
            nape: 'Nape',
            back: 'Back',
            handIn: 'Hand In',
            handOut: 'Hand Out',
            belly: 'Belly',
            footUp: 'Foot Up',
            footDown: 'Foot Down',
            rightSide: 'Right Side of the Body',
            leftSide: 'Left Side of the Body',
            description: 'Here we go with your measured data of the app.',
            ear: 'Ear',
        },
    },
    currentLanguage: 'en',
};

const TranslateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_TRANSLATE":
            return {...state, ...action.payload}
        case "GET_TRANSLATE":
            return state[action.payload];
        default:
            return state;
    }
}

export default TranslateReducer;
