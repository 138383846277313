import React, {useEffect, useState} from 'react';
import {
    ActivityIndicator,
    Alert,
    Dimensions, Linking,
    Platform,
    ScrollView,
    Switch,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import Button from "../components/Button";
import Colors from "../../res/constants/Colors";
import Header from '../components/Header';
import LoginButton from "../components/HeaderButtons/LoginButton";
import LanguageChangeButton from "../components/HeaderButtons/LanguageChangeButton";
import {connect} from 'react-redux';
import {setUser} from "../redux/actions/UserActions";
import Space from "../components/Space";
import HeaderLogo from "../components/HeaderButtons/HeaderLogo";
import * as firebase from 'firebase';
import StyleGuide from "../../res/constants/StyleGuide";
import AutoHeightImage from "../components/AutoHeightImage";
import AsyncStorage from '@react-native-async-storage/async-storage';

const platformSpecific = Platform.select({
    ios: {
        containerPadding: 10,
        containerVerticalMargin: 30,
    },
    android: {
        containerPadding: 15,
        containerVerticalMargin: 0,
    },
    web: {
        containerPadding: 30,
        containerVerticalMargin: 30,
    },
});

function Landing(props) {
    const translations = props.translate[props.translate.currentLanguage];
    const [role, setRole] = useState('customer');
    const [isLoading, setIsLoading] = useState(true);

    if (props.user.isLoggedIn === true && props.user.familyMembers === null) {
        firebase.database().ref('users/' + props.user.data.user.uid).once('value').then(snapshot => {
            const familyMembers = [];
            if (null !== snapshot.val()) {
                const keys = Object.keys(snapshot.val().familyMembers || []);
                keys.forEach(key => {
                    const memberObject = {id: key, ...snapshot.val().familyMembers[key]};
                    familyMembers.push(memberObject);
                })
            }
            props.setUser({familyMembers});
        }).catch(err => {
            console.log('ERROR WHILE FETCHING FAMILY MEMBERS', err);
            props.setUser({familyMembers: []});
        });
    }

    if (props.user.hasInitialized === false) {
        AsyncStorage.getItem('userData').then(data => {
            data = JSON.parse(data);
            if (null !== data) {
                setIsLoading(true);
                // Fetch user data from firebase
                firebase.database().ref('users/' + data.user.uid).once('value').then(snapshot => {
                    let isActive = false;
                    if (null != snapshot.val()) {
                        isActive = snapshot.val().isActive;
                        if (undefined === isActive) {
                            isActive = false;
                        }
                    }
                    props.setUser({
                        isLoggedIn: true,
                        data: data,
                        isActive: isActive,
                    });
                }).catch(err => {
                    Alert.alert("Error", err.message);
                }).finally(() => {
                    setIsLoading(false);
                });
            } else {
                props.setUser({
                    isLoggedIn: false,
                    data: {},
                });
            }
        }).catch(err => {
            props.setUser({
                isLoggedIn: false,
                data: {},
            });
        }).finally(() => {
            setIsLoading(false);
            props.setUser({
                hasInitialized: true,
            });
        });
    }

    let i = 1;

    useEffect(() => {
        if (props.user != undefined && props.user.isLoggedIn) {
            firebase.database().ref('/users/' + props.user.data.user.uid).once('value').then(snapshot => {
                const role = snapshot.val().role;

                if (snapshot.val() !== null && role !== undefined) {
                    ['consultant', 'expert', 'junior', 'admin', 'technical'].indexOf(role) !== -1 ? setRole(snapshot.val().role) : setRole('customer');
                }
            });
        }
    }, [props.user]);

    return (
        <>
            {
                isLoading ?
                    <View style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: Colors.mainBackground
                    }}><ActivityIndicator size={'large'} color={Colors.primary}/></View> :
                    <View style={styles.wrapper}>
                        <Header
                            headerLeft={() => (<LoginButton/>)}
                            headerCenter={() => (props.user.isLoggedIn ? <HeaderLogo/> : <></>)}
                            headerRight={() => (<LanguageChangeButton/>)}
                        />
                        <Space />
                        <ScrollView contentContainerStyle={styles.mainContent}>
                            {
                                props.user.isLoggedIn ?
                                    <>
                                        {role !== 'customer' && <Button label={'DASHBOARD'} onPress={() => {
                                            Linking.openURL('https://nitt.app/panel');
                                        }} />}
                                        <Space />
                                        <TouchableOpacity
                                            onPress={() => {
                                                if (props.user.isActive === true) {
                                                    props.navigation.navigate('PastAnalysis');
                                                } else {
                                                    Alert.alert(
                                                        'Warning',
                                                        'This function is available only for active members. If you want to get an active membership, please ask for "FREE ADVISING"',
                                                        [
                                                            {
                                                                text: 'Free Advising',
                                                                onPress: () => props.navigation.navigate('FreeAdvising')
                                                            },
                                                            {
                                                                text: 'OK',
                                                                onPress: () => console.log('Cancel Pressed'),
                                                                style: 'cancel'
                                                            },
                                                        ],
                                                    );
                                                }
                                            }}
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <AutoHeightImage source={require('../../res/images/past-data.png')}
                                                             width={Dimensions.get('window').width - platformSpecific.containerPadding}/>
                                            <Text style={{
                                                textShadowColor: 'rgba(0, 0, 0, 0.75)',
                                                textShadowOffset: {width: -1, height: 1},
                                                textShadowRadius: 10,
                                                color: '#FFF',
                                                fontSize: 24,
                                                fontFamily: 'Montserrat_600SemiBold',
                                                textAlign: 'center',
                                                position: 'absolute',
                                                top: 30 + '%',
                                                padding: platformSpecific.containerPadding,
                                            }}>{props.user.currentFamilyMember === null ? translations.landing.userPastData : translations.landing.memberPastData}</Text>
                                        </TouchableOpacity>
                                        <Space/>
                                        <View style={styles.familyMembers}>
                                            <Text
                                                style={styles.familyMembersHeading}>{translations.landing.familyMembers}</Text>
                                            <Space height={18}/>
                                            <TouchableOpacity
                                                key={"member-" + i}
                                                onPress={() => {
                                                    props.setUser({
                                                        currentFamilyMember: null,
                                                    });
                                                }}
                                                style={styles.familyMemberWrapper}
                                            >
                                                <AutoHeightImage source={require('../../res/images/triangle.png')}
                                                                 width={15} style={{
                                                    marginRight: 10,
                                                    opacity: props.user.currentFamilyMember === null ? 1 : 0
                                                }}/><Text
                                                style={{...styles.familyMembersHeading, ...{textAlign: 'left'}}}>{i}- {translations.landing.me}</Text>
                                            </TouchableOpacity>
                                            {props.user.familyMembers !== null ? props.user.familyMembers.map(member => {
                                                i++;
                                                return (
                                                    <TouchableOpacity
                                                        key={"member-" + i}
                                                        onPress={() => {
                                                            props.setUser({
                                                                currentFamilyMember: {id: member.id, name: member.name, nickname: member.nickname},
                                                            });
                                                        }}
                                                        style={styles.familyMemberWrapper}
                                                    >
                                                        <AutoHeightImage
                                                            source={require('../../res/images/triangle.png')} width={15}
                                                            style={{
                                                                marginRight: 10,
                                                                opacity: props.user.currentFamilyMember !== null && props.user.currentFamilyMember.id === member.id ? 1 : 0
                                                            }}/><Text
                                                        style={{...styles.familyMembersHeading, ...{textAlign: 'left'}}}>{i}- {member.nickname}</Text>
                                                    </TouchableOpacity>
                                                );
                                            }) : <ActivityIndicator size={'large'} color={Colors.mainBackground}/>}
                                            {
                                                props.user.familyMembers !== null && props.user.familyMembers.length < 4 ?
                                                    <TouchableOpacity
                                                        style={styles.familyMembersAddWrapper}
                                                        onPress={() => {
                                                          props.navigation.navigate('AddMember');
                                                        }}>
                                                        <Text
                                                            style={styles.familyMembersHeading}
                                                        >{translations.landing.addMember}</Text>
                                                        <AutoHeightImage
                                                            source={require('../../res/images/add-member.png')}
                                                            width={50}
                                                        />
                                                    </TouchableOpacity> :
                                                    <></>
                                            }
                                        </View>
                                        {/*<View style={{*/}
                                        {/*    flexDirection: 'row',*/}
                                        {/*    alignItems: 'center',*/}
                                        {/*    justifyContent: 'center',*/}
                                        {/*}}>*/}
                                        {/*    <Text style={{*/}
                                        {/*        fontFamily: 'Montserrat_600SemiBold',*/}
                                        {/*        fontSize: 18,*/}
                                        {/*        color: '#FFF',*/}
                                        {/*    }}>{translations.landing.earTesting}</Text>*/}
                                        {/*    <Switch*/}
                                        {/*        trackColor={{false: '#d3d3d3', true: '#f1f1f1'}}*/}
                                        {/*        thumbColor={Colors.primary}*/}
                                        {/*        ios_backgroundColor="#3e3e3e"*/}
                                        {/*        onValueChange={(val) => {*/}
                                        {/*            props.setUser({*/}
                                        {/*                earTesting: val,*/}
                                        {/*            });*/}
                                        {/*        }}*/}
                                        {/*        value={props.user.earTesting}*/}
                                        {/*        style={{*/}
                                        {/*            marginLeft: 10,*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*</View>*/}
                                        <Space/>
                                    </> :
                                    <View style={{alignItems: 'center', justifyContent: 'center', paddingHorizontal: 10,}}>
                                        <AutoHeightImage width={Dimensions.get('window').width * .3} source={require('../../res/images/logo.png')}/>
                                        <Text style={styles.heading}>{translations.landing.heading}</Text>
                                        <Text
                                            style={styles.secondaryHeading}>{translations.landing.secondaryHeading}</Text>
                                        <View style={styles.sloganWrapper}>
                                            <Text
                                                style={styles.sloganHeading}>{translations.landing.sloganHeading}</Text>
                                            <Text
                                                style={styles.sloganDescription}>{translations.landing.sloganDescription}</Text>
                                            <TouchableOpacity onPress={() => {
                                                Linking.openURL(translations.landing.sloganLink);
                                            }}>
                                                <Text
                                                    style={styles.sloganButton}>{translations.landing.sloganButton}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                            }
                        </ScrollView>
                        <View style={styles.startButtonWrapper}>
                            <Button label={translations.landing.startTestButton} onPress={() => {
                                props.navigation.navigate("BeforeStart")
                            }} />
                        </View>
                    </View>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: state.TranslateReducer,
        user: state.UserReducer,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing);

const styles = {
    wrapper: {
        alignItems: 'center',
        backgroundColor: Colors.mainBackground,
        minHeight: Dimensions.get('window').height,
        flex: 1,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: platformSpecific.containerPadding,
        marginTop: platformSpecific.containerVerticalMargin,
        width: 100 + '%',
    },
    heading: {
        color: Colors.light,
        fontFamily: 'Montserrat_800ExtraBold',
        fontSize: StyleGuide.fontSize.huge,
        textAlign: 'center',
    },
    secondaryHeading: {
        color: Colors.light,
        fontFamily: 'Montserrat_800ExtraBold',
        fontSize: StyleGuide.fontSize.secondaryHeading,
        textAlign: 'center',
    },
    sloganWrapper: {
        marginTop: 36,
        marginBottom: 30,
        borderWidth: 1,
        borderColor: Colors.light,
        paddingHorizontal: 25,
        paddingVertical: StyleGuide.paddings.normal,
    },
    sloganHeading: {
        color: Colors.light,
        fontFamily: 'Montserrat_300Light',
        fontSize: StyleGuide.fontSize.big,
        textAlign: 'center',
        marginBottom: StyleGuide.margins.normal,
    },
    sloganDescription: {
        color: Colors.light,
        fontFamily: 'Montserrat_300Light',
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 20,
    },
    sloganButton: {
        fontFamily: 'Montserrat_800ExtraBold',
        paddingVertical: 5,
        textAlign: 'center',
        color: Colors.primary,
    },
    familyMembers: {
        backgroundColor: Colors.primary,
        padding: 15,
    },
    familyMemberWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    familyMembersHeading: {
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: 24,
        color: '#FFF',
        textAlign: 'center',
    },
    familyMembersAddWrapper: {
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    mainContent: {
        width: 100 + '%',
    },
    startButtonWrapper: {
        marginVertical: 10,
    },
};
