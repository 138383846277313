import React, {useState} from 'react';
import {
    ActivityIndicator,
    Alert,
    Dimensions,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TextInput,
    View
} from "react-native";
import Button from "../components/Button";
import Colors from "../../res/constants/Colors";
import Header from '../components/Header';
import LanguageChangeButton from "../components/HeaderButtons/LanguageChangeButton";
import {connect} from 'react-redux';
import MenuBackButton from "../components/HeaderButtons/MenuBackButton";
import Space from "../components/Space";
import * as firebase from 'firebase';

import 'firebase/auth';
import {setUser} from "../redux/actions/UserActions";
import AutoHeightImage from "../components/AutoHeightImage";
import StyleGuide from "../../res/constants/StyleGuide";
import * as SecureStore from "expo-secure-store";

const platformSpecific = Platform.select({
    ios: {
        containerPadding: 10,
    },
    android: {
        containerPadding: 25,
    },
    web: {
        containerPadding: 30,
    },
});

function ForgotPassword(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');
    const [isInProcess, setIsInProcess] = useState(false);
    const [mailSent, setMailSent] = useState(false);

    const translations = props.translate[props.translate.currentLanguage];
    return (
        <ScrollView contentContainerStyle={styles.wrapper}>
            <Header
                headerLeft={() => (<MenuBackButton/>)}
                headerCenter={() => (<></>)}
                headerRight={() => (<LanguageChangeButton/>)}
            />
            <View style={styles.container}>
                <AutoHeightImage width={Dimensions.get('window').width * 0.3} source={require('../../res/images/logo.png')}/>
                <Space height={50}/>
                <Text style={styles.secondaryHeading}>{translations.general.resetYourPassword}</Text>
                <Space height={20}/>
                {mailSent && (
                    <>
                        <Text style={styles.warningMessage}>{translations.general.passwordResetEmailSent}</Text>
                        <Space height={20}/>
                    </>
                )}
                <TextInput
                    style={styles.textInput}
                    onChangeText={setEmail}
                    keyboardType={"email-address"}
                    placeholder={translations.placeholders.email}
                />
                {
                    isInProcess ?
                    <ActivityIndicator size={"large"} color={Colors.primary} /> :
                    <Button
                        label={translations.general.resetPasswordButton} wrapperStyle={{width: 100 + '%'}}
                        onPress={() => {
                            if (email !== '') {
                                setIsInProcess(true);
                                firebase.auth().sendPasswordResetEmail(email).then(user => {
                                    Alert.alert(translations.general.success, translations.general.passwordResetEmailSent);
                                    setMailSent(true);
                                }).catch(err => {
                                    Alert.alert(translations.general.errorOccurred, err.message);
                                }).finally(() => {
                                    setIsInProcess(false);
                                });
                            } else {
                                Alert.alert(translations.general.warning, translations.general.fillAll);
                            }
                        }}
                    />
                }
                <Space />
            </View>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: state.TranslateReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

const styles = {
    wrapper: {
        alignItems: 'center',
        backgroundColor: Colors.mainBackground,
        minHeight: Dimensions.get('window').height,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: platformSpecific.containerPadding,
        width: 100 + '%',
    },
    heading: {
        color: Colors.light,
        fontFamily: 'Montserrat_800ExtraBold',
        fontSize: StyleGuide.fontSize.huge,
        textAlign: 'center',
    },
    secondaryHeading: {
        color: Colors.light,
        fontFamily: 'Montserrat_800ExtraBold',
        fontSize: StyleGuide.fontSize.secondaryHeading,
        textAlign: 'center',
    },
    warningMessage: {
        color: Colors.light,
        fontFamily: 'Montserrat_400Regular',
        fontSize: 18,
        textAlign: 'center',
    },
    textInput: {
        width: 100 + '%',
        height: 61,
        backgroundColor: '#FFF',
        borderColor: '#DDD',
        borderTopWidth: 3,
        padding: 10,
        marginBottom: 26,
        textAlign: 'center',
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: 22,
        color: '#989898',
    },
};
